<template>
  <default-layout no-auth>
    <form>
      <input type="hidden" name="regist" value="true">
      <div class="contents-block has-blue-border">
        <div class="contents-block__body is-narrow">
          <div class="c-block">
            <h1 class="c-heading__lv1"><span class="c-heading__title">パスワードをお忘れの方</span></h1>
          </div>
          <template v-if="tokenError">
            <alert></alert>
          </template>
          <template v-else-if="updated">
            <p class="c-text">パスワードを更新しました。<br>ログイン画面から新しいパスワードでログインしてください。</p>
          </template>
          <template v-else>
            <alert></alert>
            <div class="c-block u-tac">
              <p class="c-text">新しいパスワードを入力してください。</p>
            </div>
            <div class="c-block">
              <dl class="c-form-block is-narrow">
                <dt>
                  <label class="c-form-label" for="">パスワード</label>
                </dt>
                <dd>
                  <input class="c-input__text" type="password" autocomplete="new-password" placeholder="" v-model="form.newPassword">
                  <field-error :errors="errors" name="newPassword"></field-error>
                  <div class="c-form-ex">
                    <p class="c-form-ex__text">英字・数字をそれぞれ１文字以上含め、8文字以上入力してください。</p>
                  </div>
                </dd>
                <dt>
                  <label class="c-form-label" for="">パスワード（確認用）</label>
                </dt>
                <dd>
                  <input class="c-input__text" type="password" autocomplete="new-password" placeholder="" v-model="form.newPasswordConfirm">
                  <field-error :errors="errors" name="newPasswordConfirm"></field-error>
                  <div class="c-form-ex">
                    <p class="c-form-ex__text">上のパスワードと同じ内容を入力してください。</p>
                  </div>
                </dd>
              </dl>
            </div>
          </template>
        </div>
        <div class="contents-block__footer">
          <button v-if="tokenError || updated" class="c-btn__secondary is-short u-ma" type="button" @click="$move('/home')"><span>ログイン画面へ</span></button>
          <button v-else class="c-btn is-short u-ma" type="button" @click="update"><span>登録</span></button>
        </div>
      </div>
    </form>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";

export default {
  data () {
    return {
      form: {
        token: "",
        newPassword: "",
        newPasswordConfirm: ""
      },
      tokenError: false,
      updated: false,
      errors: {}
    };
  },
  created() {
    this.form.token = window.location.hash.substring(1);
    Service.checkToken(this.form.token)
        .catch(() => {
          this.tokenError = true;
        });
  },
  methods: {
    update () {
      Service.update(this.form)
          .then(() => {
            this.updated = true;
          })
          .catch(e => {
            this.errors = e.fieldErrors;
          });
    }
  }
}
</script>
