<template>
  <default-layout no-auth>
    <form>
      <div class="contents-block has-blue-border">
        <div class="contents-block__body is-narrow">
          <div class="c-block">
            <h1 class="c-heading__lv1"><span class="c-heading__title">パスワードをお忘れの方</span></h1>
          </div>
          <alert></alert>
          <template v-if="!sent">
            <div class="c-block u-tac">
              <p class="c-text">メールアドレスを入力してください。<br>パスワード再設定の案内メールを送信します。</p>
            </div>
            <div class="c-block">
              <dl class="c-form-block is-narrow">
                <dt>
                  <label class="c-form-label" for="">メールアドレス</label>
                </dt>
                <dd>
                  <input class="c-input__text" type="email" v-model="email">
                  <field-error :errors="errors" name="email"></field-error>
                </dd>
              </dl>
            </div>
          </template>
          <template v-else>
            <div class="c-block u-tac">
              <p class="c-text">{{email}} 宛てにメールを送信しました。<br>メール文中のURLからパスワードを再設定してください。</p>
            </div>
          </template>
        </div>
        <div class="contents-block__footer">
          <button v-if="!sent" class="c-btn is-short u-ma" type="button" @click="send"><span>送信</span></button>
          <button v-else class="c-btn__secondary is-short u-ma" type="button" @click="back"><span>戻る</span></button>
        </div>
      </div>
    </form>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";

export default {
  data() {
    return {
      email: "",
      sent: false,
      errors: {}
    }
  },
  methods: {
    send() {
      Service.sendResetUrl(this.email)
          .then(() => {
            this.sent = true;
          })
          .catch(e => {
            this.errors = e.fieldErrors;
          })
    },
    back() {
      this.email = '';
      this.sent = false;
    }
  }
}
</script>
